<template>
  <form @input="$emit('input', formattedLetterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <h6>Main phrase</h6>
      <input
        type="text"
        v-model="item.mainPhrase.text"
        placeholder="Main phrase"
      />
      <input
        type="text"
        v-model="item.mainPhrase.audio"
        placeholder="Audio link"
      />
      <input
        type="text"
        v-model="item.mainPhrase.answer"
        placeholder="Answer text"
      />

      <div class="d-flex">
        <label>
          <input
            type="radio"
            v-model.number="item.mainPhrase.index"
            :value="1"
            placeholder="Order. Allowed only"
          />
          <span class="ml-10">First</span>
        </label>
        <label class="ml-40">
          <input
            type="radio"
            v-model.number="item.mainPhrase.index"
            :value="2"
            placeholder="Order. Allowed only"
          />
          <span class="ml-10">Second</span>
        </label>
      </div>

      <div class="ml-40">
        <h6>Phrases</h6>
        <div v-for="(phrase, j) in item.phrases" :key="j">
          <input type="text" v-model="phrase.text" placeholder="Phrase" />
          <input
            type="text"
            v-model="phrase.audio"
            placeholder="Phrase Audio link"
          />
        </div>

        <button type="button" @click="addInner(item.phrases)">
          + Insert phrase
        </button>
      </div>
    </div>

    <button type="button" @click="addItem">
      + Add Item
    </button>
  </form>
</template>

<script>
// "letterWords": [
// {
//   "mainPhrase": {
//     "text": "Hello, how are you?",
//     "audio": "/type-11-a/hello-1.mp3",
//     "index": 1,
//     "answer": "I'm fine, thank you"
//   },
//   "phrases": [
//     {
//       "text": "I'm fine, thank you",
//       "audio": "/type-11-a/hello-2.mp3"
//     },
//     {
//       "text": "I'm a big gorilla",
//       "audio": "/type-11-a/phrase-1.mp3"
//     },
//     {
//       "text": "I am 10 years old",
//       "audio": "/type-11-a/phrase-2.mp3"
//     }
//   ]
// },
// ]

export default {
  name: 'Type9a',
  data() {
    return {
      letterWords: [
        {
          mainPhrase: {
            text: '',
            audio: '',
            index: 1,
            answer: '',
          },
          phrases: [
            {
              text: '',
              audio: '',
            },
          ],
        },
      ],
    };
  },
  computed: {
    formattedLetterWords() {
      // let result = JSON.parse(JSON.stringify(this.letterWords));

      return this.letterWords;
    },
  },
  methods: {
    addItem() {
      this.letterWords.push({
        mainPhrase: {
          text: '',
          audio: '',
          index: 1,
          answer: '',
        },
        phrases: [
          {
            text: '',
            audio: '',
          },
        ],
      });
    },
    addInner(phrase) {
      phrase.push({
        text: '',
        audio: '',
      });
    },
  },
};
</script>
